import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { LayoutService } from "../services/layout.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { MatSidenav, MatSidenavContainer } from "@angular/material/sidenav";
import { Event, NavigationEnd, Router, Scroll } from "@angular/router";
import { filter, map, startWith, withLatestFrom } from "rxjs/operators";
import { checkRouterChildsData } from "../utils/check-router-childs-data";
import { DOCUMENT } from "@angular/common";
import { ConfigService } from "../services/config.service";
import { Subscription } from "rxjs";
import { NotificationService } from "src/app/_modules/notification/notification.service";
import { ConfirmDialogComponent } from "src/app/_shared/_components/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";


@UntilDestroy()
@Component({
  selector: "vex-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  @Input() sidenavRef: TemplateRef<any>;
  @Input() toolbarRef: TemplateRef<any>;
  @Input() footerRef: TemplateRef<any>;
  @Input() quickpanelRef: TemplateRef<any>;

  isLayoutVertical$ = this.configService.config$.pipe(
    map((config) => config.layout === "vertical")
  );
  isBoxed$ = this.configService.config$.pipe(map((config) => config.boxed));
  isToolbarFixed$ = this.configService.config$.pipe(
    map((config) => config.toolbar.fixed)
  );
  isFooterFixed$ = this.configService.config$.pipe(
    map((config) => config.footer.fixed)
  );
  isFooterVisible$ = this.configService.config$.pipe(
    map((config) => config.footer.visible)
  );
  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isDesktop$ = this.layoutService.isDesktop$;

  scrollDisabled$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() =>
      checkRouterChildsData(
        this.router.routerState.root.snapshot,
        (data) => data.scrollDisabled
      )
    )
  );

  containerEnabled$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() =>
      checkRouterChildsData(
        this.router.routerState.root.snapshot,
        (data) => data.containerEnabled
      )
    )
  );

  searchOpen$ = this.layoutService.searchOpen$;
  getNotificationListValue: Subscription;
  notificationList = [];
  newNotification = false;
  received = false;
  notificationPanel = false;

  itsmNotificationsList = [];
  selectedNotifications = [];

  @ViewChild("quickpanel", { static: true }) quickpanel: MatSidenav;
  @ViewChild("sidenav", { static: true }) sidenav: MatSidenav;
  @ViewChild(MatSidenavContainer, { static: true })
  sidenavContainer: MatSidenavContainer;

  allSelected: boolean = false;

  updateAllComplete() {
    this.allSelected = this.itsmNotificationsList.every(t => t.selected);
  }

  someSelected(): boolean {
    if (this.itsmNotificationsList == null) {
      return false;
    }
    return this.itsmNotificationsList.filter(t => t.selected).length > 0 && !this.allSelected;
  }

  selectAll(selected: boolean) {
    this.allSelected = selected;
    if (this.itsmNotificationsList == null) {
      return;
    }
    this.itsmNotificationsList.forEach(t => t.selected = selected);
  }

  constructor(
    private cd: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private router: Router,
    private dialog: MatDialog,
    private _notificationService: NotificationService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.getNotificationListValue = this._notificationService
      .getNotificationListValue()
      .pipe()
      .subscribe((data) => {
        if (data) {
          this.getUnAcknowledgedITSMNotifications();
          // this.notificationList = this._notificationService.notificationList;
          this.newNotification = true;
          const audio = new Audio('assets/sound/notification.mp3');
          audio.play();
          this.received = false;
          setTimeout(() => {
            this.received = true;
          }, 300);
        }
      });
  }

  closeNotification() {
    this.received = false;
    setTimeout(() => {
      this.newNotification = false;
    }, 300);
  }

  acknowledgeAllTickets() {
    this.selectedNotifications = [];
    this.itsmNotificationsList.map(item => {
      if (item.selected) {
        this.selectedNotifications.push(
          { id: item.id }
        )
      }
    });
    this.dialog
      .open(ConfirmDialogComponent, {
        width: "400px",
        data: {
          action: "Acknowledge",
          module: "",
          undone: false,
          color: "primary",
        },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.acknowledgeITSMTickets();
        }
      });

  }

  acknowledgeTicket(notification) {
    this.selectedNotifications = [];
    this.selectedNotifications.push(
      { id: notification.id }
    );
    this.acknowledgeITSMTickets();
  }

  acknowledgeITSMTickets() {
    this._notificationService.acknowledgeItsmNotifications(this.selectedNotifications)
      .subscribe(data => {
        this.getUnAcknowledgedITSMNotifications()
      })
  }

  closeNotificationPanel() {
    this.selectAll(false);
    this.layoutService.closeNotificationPanel();
    // this.notificationPanel = !this.notificationPanel;
  }

  getUnAcknowledgedITSMNotifications() {
    this._notificationService.getItsmUANotifications()
      .subscribe(list => {
        list.forEach(item => {
          item.selected = false;
        });

        this.itsmNotificationsList = list;
      })
  }

  ngOnInit() {
    this.getUnAcknowledgedITSMNotifications()
    /**
     * Expand Sidenav when we switch from mobile to desktop view
     */
    this.isDesktop$
      .pipe(
        filter((matches) => !matches),
        untilDestroyed(this)
      )
      .subscribe(() => this.layoutService.expandSidenav());

    /**
     * Open/Close Quickpanel through LayoutService
     */
    this.layoutService.quickpanelOpen$
      .pipe(untilDestroyed(this))
      .subscribe((open) =>
        open ? this.quickpanel.open() : this.quickpanel.close()
      );

    /**
     * Open/Close Notificationpanel through LayoutService
     */
    this.layoutService.notificationPanelOpen$
      .pipe(untilDestroyed(this))
      .subscribe((open) =>
        open
          ? (this.notificationPanel = true)
          : (this.notificationPanel = false)
      );

    /**
     * Open/Close Sidenav through LayoutService
     */
    this.layoutService.sidenavOpen$
      .pipe(untilDestroyed(this))
      .subscribe((open) => (open ? this.sidenav.open() : this.sidenav.close()));

    /**
     * Mobile only:
     * Close Sidenav after Navigating somewhere (e.g. when a user clicks a link in the Sidenav)
     */
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        withLatestFrom(this.isDesktop$),
        filter(([event, matches]) => !matches),
        untilDestroyed(this)
      )
      .subscribe(() => this.sidenav.close());
  }

  ngAfterViewInit(): void {
    /**
     * Enable Scrolling to specific parts of the page using the Router
     */
    this.router.events
      .pipe(
        filter<Event, Scroll>((e: Event): e is Scroll => e instanceof Scroll),
        untilDestroyed(this)
      )
      .subscribe((e) => {
        if (e.position) {
          // backward navigation
          this.sidenavContainer.scrollable.scrollTo({
            start: e.position[0],
            top: e.position[1],
          });
        } else if (e.anchor) {
          // anchor navigation

          const scroll = (anchor: HTMLElement) =>
            this.sidenavContainer.scrollable.scrollTo({
              behavior: "smooth",
              top: anchor.offsetTop,
              left: anchor.offsetLeft,
            });

          let anchorElem = this.document.getElementById(e.anchor);

          if (anchorElem) {
            scroll(anchorElem);
          } else {
            setTimeout(() => {
              anchorElem = this.document.getElementById(e.anchor);
              scroll(anchorElem);
            }, 100);
          }
        } else {
          // forward navigation
          this.sidenavContainer.scrollable.scrollTo({
            top: 0,
            start: 0,
          });
        }
      });
  }
}
