<div
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async"
  class="sidenav flex flex-col"
>
  <div class="sidenav-toolbar flex-none flex items-center">
    <!-- <img [src]="imageUrl$ | async" class="logo-dark select-none flex-none" /> -->
    <!-- <img
      [src]="darkImageUrl$ | async"
      class="logo-light select-none flex-none"
    /> -->
    <img
      *ngIf="!darkLogo"
      src="../../../../../assets/img/logo-dark-theme.png"
      alt="Noc Logo"
      class="logo-dark select-none flex-none"
    />
    <img
      *ngIf="darkLogo"
      src="../{{ darkLogo.filePath }}"
      alt="Noc Logo"
      class="logo-dark select-none flex-none"
    />
    <img
      *ngIf="!lightLogo"
      src="../../../../../assets/img/logo-light-theme.png"
      alt="Noc Logo"
      class="logo-light select-none flex-none"
    />
    <img
      *ngIf="lightLogo"
      src="../{{ lightLogo.filePath }}"
      alt="Noc Logo"
      class="logo-light select-none flex-none"
    />

    <h2 class="title rtl:pr-4 select-none flex-auto"></h2>
    <button
      (click)="toggleCollapse()"
      *ngIf="showCollapsePin$ | async"
      class="w-8 h-8 -mr-2 leading-none flex-none hidden lg:block"
      mat-icon-button
      type="button"
    >
      <mat-icon
        *ngIf="!collapsed"
        [icIcon]="icRadioButtonChecked"
        size="30px"
      ></mat-icon>
      <mat-icon
        *ngIf="collapsed"
        [ngClass]="'rotate90'"
        [icIcon]="icRadioButtonUnchecked"
        size="30px"
      ></mat-icon>
    </button>
  </div>

  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items">
      <vex-sidenav-item
        [notificationList]="itsmNotificationsList"
        *ngFor="let item of items; trackBy: trackByRoute"
        [item]="item"
        [level]="0"
      ></vex-sidenav-item>
    </div>
  </vex-scrollbar>
</div>
