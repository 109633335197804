import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private _httpClient: HttpClient) { }
  private notifications = [];

  public NotificationReceivedSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  // get value of clicked node
  getNotificationListValue() {
    return this.NotificationReceivedSubject.asObservable();
  }

  get notificationList() {
    return this.notifications;
  }

  private hubConnection!: signalR.HubConnection;
  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}/NocNotification`)
      .build();
    this.hubConnection
      .start()
      .then(() => console.log("Connection started"))
      .catch((err: string) =>
        console.log("Error while starting connection: " + err)
      );
  };

  public addTransferChartDataListener = () => {
    this.hubConnection.on("ReceiveMessage", (data: any) => {
      this.notifications.push(data);
      this.NotificationReceivedSubject.next(true);
    });
  };

  getItsmUANotifications(): Observable<any[]> {
    return this._httpClient.get<any[]>(
      environment.apiUrl + "/Itsm/get-itsm-alerts"
    );
  }

  acknowledgeItsmNotifications(tickets: any[]): Observable<any[]> {
    return this._httpClient.put<any[]>(
      environment.apiUrl + "/Itsm/acknowledge-ITSMAlerts", { json: tickets }
    );
  }


}
