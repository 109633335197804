<a
  *ngIf="
    isLink(item) && !isFunction(item.route) && (item.permission || item.common)
  "
  [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
  [routerLink]="item.route"
  class="item"
  fxLayout="row"
  matRipple
  routerLinkActive="active"
>
  <span
    ><mat-icon
      [ngClass]="item.rotate === 180 ? 'rotate' : ''"
      [icIcon]="item.icon"
      class="item-icon"
      fxFlex="none"
    ></mat-icon
  ></span>

  <span class="item-label" fxFlex="auto">{{ item.label }}</span>
</a>

<a
  *ngIf="
    isImg(item) && !isFunction(item.route) && (item.permission || item.common)
  "
  [fragment]="item.fragment"
  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
  [routerLink]="item.route"
  class="item"
  fxLayout="row"
  matRipple
  routerLinkActive="active"
>
  <div
    *ngIf="!item.profilePicture"
    [ngClass]="'bg-primary'"
    class="initials-avatar avatar-sm mr-4 uppercase text-white font-semibold"
  >
    {{ item.initials }}
  </div>
  <img
    *ngIf="item.profilePicture"
    class="avatar avatar-sm mr-4 align-middle"
    src="{{ item.profilePicture }}"
    alt="Selected Image"
  />
  <mat-icon
    *ngIf="level === 0 && !item.profilePicture && !item.initials"
    [ngClass]="item.rotate === 180 ? 'rotate' : ''"
    [icIcon]="item.icon"
    class="item-icon"
    fxFlex="none"
  ></mat-icon>
  <span class="item-label" fxFlex="auto">{{ item.label }}</span>
</a>

<div
  (click)="item.route() && (item.permission || item.common)"
  *ngIf="isLink(item) && isFunction(item.route)"
  class="item"
  [ngClass]="item.label === 'Notifications' && notificationPanel ? 'active' : ''"
  fxLayout="row"
  matRipple
  routerLinkActive="active"
>
  <span
    [ngClass]="
      item.label === 'Notifications' && notificationList.length ? 'bell' : ''
    "
  >
    <mat-icon
      *ngIf="level === 0"
      [icIcon]="item.icon"
      class="item-icon"
      fxFlex="none"
    ></mat-icon>
  </span>

  <span class="item-label" fxFlex="auto">{{ item.label }}</span>
  <div
    *ngIf="item.label === 'Notifications' && notificationList.length"
    class="blink bg-red"
  ></div>
  <span
    *ngIf="item.label === 'Notifications' && notificationList.length"
    [ngClass]="[item.badge.bgClass, item.badge.textClass]"
    class="item-badge"
    fxFlex="none"
    >{{ notificationList.length }}</span
  >
</div>

<ng-container *ngIf="isDropdown(item) && (item.permission || item.common)">
  <div
    (click)="toggleOpen()"
    [class.active]="isOpen || isActive"
    [class.open]="isOpen"
    class="item"
    fxLayout="row"
    matRipple
  >
    <mat-icon
      *ngIf="level === 0"
      [icIcon]="item.icon"
      class="item-icon"
      fxFlex="none"
    ></mat-icon>
    <span class="item-label" fxFlex="auto">{{ item.label }}</span>
    <span
      *ngIf="item.badge"
      [ngClass]="[item.badge.bgClass, item.badge.textClass]"
      class="item-badge"
      fxFlex="none"
      >{{ item.badge.value }}</span
    >
    <mat-icon
      [icIcon]="icKeyboardArrowRight"
      class="item-dropdown-icon"
      fxFlex="none"
    ></mat-icon>
  </div>
  <div [@dropdown]="isOpen" class="item-dropdown">
    <vex-sidenav-item
      *ngFor="let subItem of item.children"
      [item]="subItem"
      [level]="level + 1"
    ></vex-sidenav-item>
  </div>
</ng-container>

<ng-container *ngIf="isSubheading(item)">
  <div class="subheading">{{ item.label }}</div>
  <vex-sidenav-item
    *ngFor="let subItem of item.children"
    [item]="subItem"
    [level]="0"
  ></vex-sidenav-item>
</ng-container>
