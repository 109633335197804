import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, RouterStateSnapshot } from "@angular/router";
import { BehaviorSubject, Observable, of, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { NOCUsers } from "src/app/_modules/user-management/interfaces/user-model";
import { RolePermission } from "src/app/_shared/_models/logged-in-role.model";
import { NocModule, NocModules } from "src/app/_shared/_models/modules.model";
import {
  NocUserRoleAndPermissions,
  UserRole,
} from "src/app/_shared/_models/role-and-permissions.model";
import { NocRole, NocRoles } from "src/app/_shared/_models/roles.model";
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "../auth/auth-services/authentication.service";

@Injectable({
  providedIn: "root",
})
export class AppInitService {
  private currentuserRole: UserRole[];
  private currentUserDetails: NOCUsers;
  private NocRoles: NocRoles;
  private NocModulesList: NocModules;
  private NocCurrentuserRole: NocUserRoleAndPermissions;

  public currentUserDetailsubject: BehaviorSubject<NOCUsers> =
    new BehaviorSubject<NOCUsers>(null);
  public NocLoggedInRoleSubject: BehaviorSubject<NocUserRoleAndPermissions> =
    new BehaviorSubject<NocUserRoleAndPermissions>(null);

  constructor(
    private _httpClient: HttpClient,
    private router: Router,
    private snackbar: MatSnackBar,
    private _authService: AuthenticationService
  ) {}

  // Method to subscribe to receive User details
  getcurrentUserDetailsValue() {
    return this.currentUserDetailsubject.asObservable();
  }

  // Method to subscribe to receive Role ad Permissions for user
  getRoleAndPermissionValue() {
    return this.NocLoggedInRoleSubject.asObservable();
  }

  get currentUserRole(): UserRole[] {
    return this.currentuserRole;
  }

  get nocRolesList(): NocRoles {
    return this.NocRoles;
  }

  get nocModuleList(): NocModules {
    return this.NocModulesList;
  }

  get nocCurrentuserRole(): NocUserRoleAndPermissions {
    return this.NocCurrentuserRole;
  }

  get nocCurrentUserDetails(): NOCUsers {
    return this.currentUserDetails;
  }

  // Init App with required data to load
  initApp(userId) {
    return forkJoin([
      this.initUserDetails(userId),
      this.getRolesLlist(),
      this.getMenuItems(),
      this.getCurrentUserRole(userId),
    ]).subscribe(
      ([userdetails, roles, modules, userrole]) => {
        this.NocCurrentuserRole = new NocUserRoleAndPermissions(
          userdetails,
          roles,
          userrole,
          modules
        );
        this.NocLoggedInRoleSubject.next(this.NocCurrentuserRole);
        return userrole;
      },
      (error) => {
        //TODO: re-look at message
        this.snackbar.open("You Session is Expired. Please Login Again!", "THANKS", {
          duration: 5000,
        });
        this._authService.logout();
      }
    );
  }

  // Get Logged in User details
  initUserDetails(id: number): Observable<NOCUsers> {
    return this._httpClient
      .get<NOCUsers>(environment.apiUrl + "/User/get-user-by-id/" + id)
      .pipe(
        map((user) => {
          this.currentUserDetails = new NOCUsers(user);
          const loggedInUser = new NOCUsers(user);
          this.currentUserDetailsubject.next(loggedInUser);
          return user;
        })
      );
  }

  // Get available roles available to show while creating new user
  getRolesLlist() {
    return this._httpClient
      .get<NocRole[]>(environment.apiUrl + "/Nav/get-role")
      .pipe(
        map((roles: NocRole[]) => {
          this.NocRoles = new NocRoles(roles);
          return roles;
        })
      );
  }

  // Get module names available
  getMenuItems() {
    return this._httpClient
      .get<NocModule[]>(environment.apiUrl + "/Nav/get-menu-nav")
      .pipe(
        map((modules: NocModule[]) => {
          this.NocModulesList = new NocModules(modules);
          return modules;
        })
      );
  }

  // Get Assigned role fo logged in User
  getCurrentUserRole(userid: number) {
    return this._httpClient
      .get<RolePermission[]>(`${environment.apiUrl}/Nav/${userid}`)
      .pipe(
        map((role: RolePermission[]) => {
          this.currentuserRole = role;
          return role;
        })
      );
  }
}
