import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  LOCALE_ID,
  OnDestroy,
} from "@angular/core";
import { LayoutService } from "../../../@vex/services/layout.service";
import { filter, map, startWith } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { checkRouterChildsData } from "../../../@vex/utils/check-router-childs-data";
import { ConfigService } from "../../../@vex/services/config.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SidebarComponent } from "../../../@vex/components/sidebar/sidebar.component";
import { AuthenticationService } from "../../_core/auth/auth-services/authentication.service";
import { Style, StyleService } from "src/@vex/services/style.service";
import { DOCUMENT } from "@angular/common";
import { ConfigName } from "src/@vex/interfaces/config-name.model";
import { colorVariables } from "src/@vex/components/config-panel/color-variables";
import { NOCUsers } from "src/app/_modules/user-management/interfaces/user-model";
import { AppInitService } from "../services/app-init.service";

import icLayers from "@iconify/icons-ic/twotone-layers";
import icAsset from "@iconify/icons-ic/round-developer-board";
import icUsers from "@iconify/icons-ic/outline-people-alt";
import icBuilding from "@iconify/icons-ic/outline-domain";
import icNotifications from "@iconify/icons-ic/outline-notifications-none";
import icDevices from "@iconify/icons-ic/outline-devices-other";
import icFolder from "@iconify/icons-ic/outline-folder-open";
import icReports from "@iconify/icons-ic/outline-feed";
import icConfigurations from "@iconify/icons-ic/outline-tune";
import icSupportTickets from "@iconify/icons-ic/outline-local-activity";
import icSuppliers from "@iconify/icons-ic/outline-local-shipping";
import icHelp from "@iconify/icons-ic/outline-pan-tool";
import icLogout from "@iconify/icons-ic/outline-logout";
import icAccount from "@iconify/icons-ic/outline-account-circle";
import icRoute from "@iconify/icons-ic/baseline-share-location";
import icLabel from "@iconify/icons-ic/outline-label";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NavigationItem } from "src/@vex/interfaces/navigation-item.interface";
import { Subscription } from "rxjs";
import { NocRole } from "src/app/_shared/_models/roles.model";
import { NocUserRoleAndPermissions } from "src/app/_shared/_models/role-and-permissions.model";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "src/app/_shared/_components/confirm-dialog/confirm-dialog.component";

//TODO: update with API data
const THEMESTYLE = [
  {
    layout: ConfigName.apollo,
    style: Style.light,
  },
  {
    layout: ConfigName.apollo,
    style: Style.default,
  },
  {
    layout: ConfigName.apollo,
    style: Style.dark,
  },
  {
    layout: ConfigName.ares,
    style: Style.light,
  },
  {
    layout: ConfigName.ares,
    style: Style.default,
  },
  {
    layout: ConfigName.ares,
    style: Style.dark,
  },
  {
    layout: ConfigName.zeus,
    style: Style.light,
  },
  {
    layout: ConfigName.zeus,
    style: Style.default,
  },
  {
    layout: ConfigName.zeus,
    style: Style.dark,
  },
];

@UntilDestroy()
@Component({
  selector: "noc-layout",
  templateUrl: "./noc-layout.component.html",
  styleUrls: ["./noc-layout.component.scss"],
})
export class NOCLayoutComponent implements OnInit, OnDestroy {
  exchangeRateApp_ID = '86b567f3118c49b48850b52ba8c3a0b2'
  // https://openexchangerates.org/api/latest.json?app_id='86b567f3118c49b48850b52ba8c3a0b2'
  ConfigName = ConfigName;
  Style = Style;
  selectedColor = colorVariables.blue;
  colorVariables = colorVariables;
  documentLoaded: boolean = false;
  currentUserDetails: NOCUsers;

  navItems: NavigationItem[] = [];

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this._configService.config$.pipe(
    map((config) => config.footer.visible)
  );
  isDesktop$ = this.layoutService.isDesktop$;
  isLgDesktop$ = this.layoutService.isLgDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(null),
    map(() =>
      checkRouterChildsData(
        this.router.routerState.root.snapshot,
        (data) => data.toolbarShadowEnabled
      )
    )
  );

  @ViewChild("configpanel", { static: true }) configpanel: SidebarComponent;

  userRoleSubscription: Subscription;
  userDetailsSubscription: Subscription; // TODO: REMOVE THIS
  role: NocUserRoleAndPermissions;

  notificationPanel = false;

  constructor(
    private layoutService: LayoutService,
    private _configService: ConfigService,
    private _authService: AuthenticationService,
    private _styleService: StyleService,
    private router: Router,
    private _initService: AppInitService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string
  ) {
    const currentUser = this._authService.currentUserValue;
    if (currentUser) {
      this._initService.initApp(currentUser.userId);
    }

    this.userDetailsSubscription = this._initService
      .getcurrentUserDetailsValue()
      .subscribe((user) => {
        if (user) {
          this.currentUserDetails = user;
          this.initThemeStyle(user);
          this.initThemeColor(user);
          if (this.role) {
            this.initMenu(this.role);
          }
        }
      });

    this.userRoleSubscription = this._initService
      .getRoleAndPermissionValue()
      .subscribe((role) => {
        this.role = role;
        if (role) {
          this.initMenu(role);
          this.documentLoaded = true;
        }
      });
  }

  initMenu(role) {
    this.navItems = [
      {
        id: 0,
        common: true,
        type: "link",
        label: "Dashboard",
        route: "/dashboard",
        icon: icLayers,
        permission: role.render(0),
      },
      {
        id: 3,
        common: false,
        permission: role.render(3),
        type: "link",
        label: "Asset Management",
        route: "/asset-management",
        icon: icAsset,
      },
      {
        id: 4,
        common: false,
        permission: role.render(4),
        type: "dropdown",
        label: "Device Management",
        icon: icDevices,
        children: [
          {
            id: 40,
            common: false,
            permission: true,
            type: "link",
            label: "Extron GVE",
            route: "/extron-gve",
            icon: icLabel
          },
          {
            id: 41,
            common: false,
            permission: true,
            type: "link",
            label: "Power IQ",
            route: "/power-iq",
            icon: icLabel
          },
        ],
      },

      {
        id: 5,
        common: false,
        permission: role.render(5),
        type: "link",
        label: "RDS Management",
        route: "/rds-management",
        icon: icBuilding,
      },
      {
        id: 1,
        common: false,
        permission: role.render(1),
        type: "link",
        label: "User Management",
        route: "/user-management",
        icon: icUsers,
      },
      {
        id: 7,
        common: false,
        permission: role.render(7),
        type: "link",
        label: "Life Cycle Roadmap",
        route: "/life-cycle-roadmap",
        icon: icRoute,
      },
      {
        id: 6,
        common: false,
        permission: role.render(6),
        type: "link",
        label: "Suppliers",
        route: "/suppliers",
        icon: icSuppliers,
      },
      {
        id: 8,
        common: false,
        permission: role.render(8),
        type: "link",
        label: "Reports",
        route: "/reports",
        icon: icReports,
      },
      {
        id: 9,
        common: false,
        permission: role.render(9),
        type: "link",
        label: "Support Tickets",
        route: "/support-tickets",
        icon: icSupportTickets,
      },
      {
        id: 10,
        common: false,
        permission: role.render(10),
        type: "link",
        label: "Configuration",
        route: "/configuration",
        icon: icConfigurations,
      },
      {
        id: 12,
        common: true,
        type: "link",
        label: "Help",
        route: "/help",
        icon: icHelp,
      },
      {
        id: 11,
        common: true,
        type: "link",
        permission: role.render(11),
        label: "Notifications",
        route: () => this.toggleNotificationPanel(),
        icon: icNotifications,
        badge: { value: "1", bgClass: "bg-primary", textClass: "text-white" },
      },
      {
        type: "subheading",
        label: "Profile",
        children: [],
      },
      {
        id: 13,
        common: true,
        type: "image",
        label: this.currentUserDetails ? this.currentUserDetails.name : "Guest",
        initials: this.currentUserDetails
          ? this.currentUserDetails.initials
          : "",
        route: "/my-profile",
        profilePicture: this.currentUserDetails.profilePicture,
        icon: icAccount,
      },
      {
        id: 14,
        common: true,
        type: "link",
        label: "Logout",
        route: () => this.logout(),
        rotate: 180,
        icon: icLogout,
      },
    ];
  }

  logout() {
    this.dialog
      .open(ConfirmDialogComponent, {
        width: "400px",
        data: {
          action: "Logout",
          module: "",
          color: 'primary',
          undone: false,
        },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this._authService.logout();
          this.snackbar.open(
            "You have been logged out Successfully",
            "THANKS",
            {
              duration: 10000,
            }
          );
        }
      });
  }

  toggleNotificationPanel() {
    this.notificationPanel
      ? this.layoutService.closeNotificationPanel()
      : this.layoutService.openNotificationPanel();
  }

  downloadTemplate(template: string) {
    window.open(`${location.origin}/templates/${template}`, "_blank");
  }

  getName(): string {
    return this.currentUserDetails ? this.currentUserDetails.name : "Guest";
  }

  ngOnInit() {
    this.layoutService.configpanelOpen$
      .pipe(untilDestroyed(this))
      .subscribe((open) =>
        open ? this.configpanel.open() : this.configpanel.close()
      );

    this.layoutService.notificationPanelOpen$
      .pipe(untilDestroyed(this))
      .subscribe((open) =>
        open
          ? (this.notificationPanel = true)
          : (this.notificationPanel = false)
      );
  }

  initThemeStyle(user) {
    if (THEMESTYLE[user.themeStyleId]) {
      this._configService.setConfig(
        THEMESTYLE[
          window.innerWidth < 1440 && window.innerWidth > 1279
            ? 6
            : user.themeStyleId
        ].layout
      );
    }
    if (THEMESTYLE[user.themeStyleId]) {
      this._styleService.setStyle(THEMESTYLE[user.themeStyleId].style);
    }
  }

  initThemeColor(user) {
    const colorId = Object.keys(colorVariables)[user.themeColorId];
    this.selectedColor = colorVariables[colorId];

    if (this.document) {
      this.document.documentElement.style.setProperty(
        "--color-primary",
        this.selectedColor.default.replace("rgb(", "").replace(")", "")
      );
      this.document.documentElement.style.setProperty(
        "--color-primary-contrast",
        this.selectedColor.contrast.replace("rgb(", "").replace(")", "")
      );
    }
  }

  ngOnDestroy() {
    this.userDetailsSubscription.unsubscribe();
    this.userRoleSubscription.unsubscribe();
  }
}
