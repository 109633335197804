export class NocModule {
  id: number;
  name: string;
  parentId: number;
  orderNumber: number;
  isRead?: boolean = false;
  isWrite?: boolean = false;
  isCreate?: boolean = false;
  isDelete?: boolean = false;
  isFull?: boolean = false;
  color?: string;

  constructor(item) {
    this.id = item.id;
    this.name = item.name;
    this.parentId = item.parentId;
    this.orderNumber = item.orderNumber;
  }
}

const colors = [
  "primary",
  "amber",
  "green",
  "deep-orange",
  "deep-purple",
  "cyan",
  "orange",
  "pink",
  "purple",
  "red",
  "teal",
];

export class NocModules {
  modules: NocModule[];
  constructor(modules: NocModule[]) {
    this.modules = modules;
    let index = 0;
    this.modules.forEach((module) => {
      module.color = colors[index];
      index = index + 1;
      if (index === colors.length) index = 0;
    });
  }
}
