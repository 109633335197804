import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { CreateUpdateAssetComponent } from "src/app/_modules/asset-management/_pages/create-update-asset/create-update-asset.component";

@Injectable()
export class CreateUpdateAssetCanDeactivateGuard
  implements CanDeactivate<CreateUpdateAssetComponent>
{
  constructor() {}

  canDeactivate(
    component: CreateUpdateAssetComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    let url: string = state.url;
    return component.canDeactivate ? component.canDeactivate() : true;

    // if (!component.isUpdating && component.countryForm.dirty) {
    //   component.isUpdating = false;
    //   return this.dialogService.confirm('Discard changes for Country?');
    // }
  }
}
