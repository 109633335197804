import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "../../../../environments/environment";
import { User } from "../interface/user";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private _httpClient: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(userName: string, password: string) {
    return this._httpClient
      .post<any>(
        `${environment.apiUrl}/User/authenticate-user?userName=${userName}&password=${password}`,
        {}
      )
      .pipe(
        map((user) => {
          localStorage.setItem("currentUser", JSON.stringify(user)); // Only for local dev
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  validateOTP(otp: string) {
    return this._httpClient
      .post<any>(
        `${environment.apiUrl}/User/validate-otp?validationCode=${otp}`,
        {}
      )
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("currentUser", JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  resetPassword(username, value) {
    return this._httpClient
      .post<any>(`${environment.apiUrl}/User/reset-password`, {
        userName: username,
        password: value.oldpassword,
        newPassword: value.confirmPassword,
      })
      .pipe(
        map((user) => {
          return user;
        })
      );
  }

  forgotPassword(username): Observable<any> {
    return this._httpClient.post(
      environment.apiUrl + "/User/forgot-password?username=" + username.email,
      {}
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    this.router.navigate(["/login"]);
  }
}
