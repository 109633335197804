import { Component, Input, OnInit } from "@angular/core";
import { trackByRoute } from "../../utils/track-by";
import { NavigationService } from "../../services/navigation.service";
import icRadioButtonChecked from "@iconify/icons-ic/round-menu-open";
import icRadioButtonUnchecked from "@iconify/icons-ic/round-bar-chart";
import { LayoutService } from "../../services/layout.service";
import { ConfigService } from "../../services/config.service";
import { map } from "rxjs/operators";
import { Subscription } from "rxjs";
import { NavigationItem } from "src/@vex/interfaces/navigation-item.interface";
import { NotificationService } from "src/app/_modules/notification/notification.service";
import { FileUploadService } from "src/app/_shared/_services/file-upload.service";

@Component({
  selector: "vex-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit {
  @Input() items: NavigationItem[];

  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(
    map((config) => config.sidenav.title)
  );
  imageUrl$ = this.configService.config$.pipe(
    map((config) => config.sidenav.imageUrl)
  );
  darkImageUrl$ = this.configService.config$.pipe(
    map((config) => config.sidenav.darkImageUrl)
  );
  showCollapsePin$ = this.configService.config$.pipe(
    map((config) => config.sidenav.showCollapsePin)
  );

  // items = this.navigationService.items;
  trackByRoute = trackByRoute;
  icRadioButtonChecked = icRadioButtonChecked;
  icRadioButtonUnchecked = icRadioButtonUnchecked;

  getNotificationListValue: Subscription;
  notificationList = [];
  darkLogo;
  lightLogo;

  itsmNotificationsList = [];

  constructor(
    private navigationService: NavigationService,
    private layoutService: LayoutService,
    private _notificationService: NotificationService,
    private configService: ConfigService,
    private _filesService: FileUploadService
  ) {
    this.getNotificationListValue = this._notificationService
      .getNotificationListValue()
      .pipe()
      .subscribe((data) => {
        if (data) {
          // this.notificationList = this._notificationService.notificationList;
        }
      });
  }

  ngOnInit() {
    this.getUnAcknowledgedITSMNotifications();
    this._notificationService.startConnection();
    this._notificationService.addTransferChartDataListener();
    this.getLogoFiles();
  }

  getUnAcknowledgedITSMNotifications(){
    this._notificationService.getItsmUANotifications()
    .subscribe(list => {
      this.itsmNotificationsList = list
    })
  }

  getLogoFiles() {
    this._filesService.getLogoFiles().subscribe((data) => {
      this.darkLogo = data.find(item => {
        item.filePath = item.filePath.replaceAll("\\", "/")
        return item.fileType === 'dark';
      });
      this.lightLogo = data.find(item => {
        item.filePath = item.filePath.replaceAll("\\", "/")
        return item.fileType === 'light';
      });

    });
  }

  onMouseEnter() {
    this.layoutService.collapseOpenSidenav();
  }

  onMouseLeave() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed
      ? this.layoutService.expandSidenav()
      : this.layoutService.collapseSidenav();
  }
}
