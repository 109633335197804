export class NocRole {
  id: number;
  name: string;
  color?: string;
}

const colors = [
  "primary",
  "amber",
  "green",
  "deep-orange",
  "deep-purple",
  "cyan",
  "orange",
  "pink",
  "purple",
  "red",
  "teal",
];

export class NocRoles {
  roles: NocRole[];
  constructor(roles: NocRole[]) {
    this.roles = roles;
    let index = 0;
    this.roles.forEach((role) => {
      role.color = colors[index];
      index = index + 1;
      if (index === colors.length) index = 0;
    });
  }

  getColor(id: number) {
    const role = this.roles.find((role) => role.id === id);
    if (role) {
      return role.color;
    }
    return "primary";
  }

  getRoleName(id: number) {
    const role = this.roles.find((role) => role.id === id);
    if (role) {
      return role.name;
    } else if (id === 0) {
      return "Super Admin";
    } else {
      return "Unknown";
    }
  }
}
