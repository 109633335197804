import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./_core/_helpers";
import { RolesAuthGuard } from "./_core/_helpers/roles.guard";
import { NOCLayoutComponent } from "./_core/noc-layout/noc-layout.component";
import { CreateUpdateAssetCanDeactivateGuard } from "./_core/guards/add-edit-asset-candeactivate.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./_core/auth/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./_core/auth/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "reset-password",
    loadChildren: () =>
      import("./_core/auth/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: "",
    component: NOCLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./_modules/dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        canActivate: [RolesAuthGuard],
        path: "user-management",
        data: { id: 1 },
        loadChildren: () =>
          import("./_modules/user-management/user-management.module").then(
            (m) => m.UserManagementModule
          ),
      },
      {
        canActivate: [RolesAuthGuard],
        path: "rds-management",
        data: { id: 5 },
        loadChildren: () =>
          import("./_modules/rds-management/rds-management.module").then(
            (m) => m.RDSManagementModule
          ),
      },
      {
        canActivate: [RolesAuthGuard],
        path: "extron-gve",
        data: { id: 4 },
        loadChildren: () =>
          import(
            "./_modules/device-management/extron-gve/extron-gve.module"
          ).then((m) => m.ExtronGVEModule),
      },
      {
        canActivate: [RolesAuthGuard],
        path: "power-iq",
        data: { id: 4 },
        loadChildren: () =>
          import("./_modules/device-management/power-iq/power-iq.module").then(
            (m) => m.PowerIQModule
          ),
      },
      {
        canActivate: [RolesAuthGuard],
        path: "life-cycle-roadmap",
        data: { id: 7 },
        loadChildren: () =>
          import("./_modules/lifecycle-roadmap/lifecycle-roadmap.module").then(
            (m) => m.LifecycleRoadmapModule
          ),
      },
      // {
      //   path: 'asset-management',
      //   loadChildren: () => import('./asset-management/add-new-asset/add-new-asset.module').then(m => m.AddNewAssetModule),
      // },

      {
        canActivate: [RolesAuthGuard],
        path: "asset-management",
        data: { id: 3 },
        loadChildren: () =>
          import("./_modules/asset-management/asset-management.module").then(
            (m) => m.AssetManagementModule
          ),
        // import(
        //   "./_modules/asset-management/asset-information/asset-information.module"
        // ).then((m) => m.AssetInformationModule),
      },

      // {
      //   path: 'asset-management',
      //   loadChildren: () => import('./asset-management/asset-management.module').then(m => m.AssetManagementModule),
      // },
      {
        canActivate: [RolesAuthGuard],
        path: "suppliers",
        data: { id: 6 },
        loadChildren: () =>
          import("./_modules/suppliers/suppliers.module").then(
            (m) => m.SuppliersModule
          ),
      },
      {
        canActivate: [RolesAuthGuard],
        path: "support-tickets",
        data: { id: 9 },
        loadChildren: () =>
          import("./_modules/support-tickets/support-tickets.module").then(
            (m) => m.SupportTicketsModule
          ),
      },
      {
        canActivate: [RolesAuthGuard],
        path: "configuration",
        data: { id: 10 },
        loadChildren: () =>
          import("./_modules/configuration/configuration.module").then(
            (m) => m.ConfigurationModule
          ),
      },
      {
        path: "my-profile",
        loadChildren: () =>
          import("./_modules/my-profile/my-profile.module").then(
            (m) => m.MyProfileModule
          ),
      },

      {
        path: "help",
        loadChildren: () =>
          import("./_modules/help/help.module").then((m) => m.NocHelpModule),
      },
      {
        canActivate: [RolesAuthGuard],
        path: "reports",
        data: { id: 10 },
        loadChildren: () =>
          import("./_modules/reports/reports.module").then(
            (m) => m.ReportsModule
          ),
      },

      // {
      //   // canActivate: [RolesAuthGuard],
      //   path: "notifications",
      //   loadChildren: () =>
      //     import("./_modules/notification/notification.module").then(
      //       (m) => m.NotificationModule
      //     ),
      // },
      {
        path: "un-authorised",
        loadChildren: () =>
          import("./_modules/unauthorised/unauthorised.module").then(
            (m) => m.UnAuthorisedModule
          ),
      },
      {
        path: "**",
        loadChildren: () =>
          import("./_core/error-500/error-500.module").then(
            (m) => m.Error500Module
          ),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
  ],
  providers: [CreateUpdateAssetCanDeactivateGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
