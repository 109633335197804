<div
  [class.boxed]="isBoxed$ | async"
  [class.horizontal-layout]="!(isLayoutVertical$ | async)"
  [class.is-mobile]="!(isDesktop$ | async)"
  [class.vertical-layout]="isLayoutVertical$ | async"
  [class.has-fixed-footer]="
    (isFooterFixed$ | async) && isFooterVisible$ | async
  "
  [class.has-footer]="isFooterVisible$ | async"
  [class.scroll-disabled]="scrollDisabled$ | async"
  [class.toolbar-fixed]="isToolbarFixed$ | async"
  [class.sidenav-collapsed]="sidenavCollapsed$ | async"
  [class.content-container]="containerEnabled$ | async"
  [class.with-search]="searchOpen$ | async"
  class="page-container"
>
  <vex-progress-bar></vex-progress-bar>

  <vex-search></vex-search>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      #sidenav
      [disableClose]="isDesktop$ | async"
      [fixedInViewport]="!(isDesktop$ | async)"
      [mode]="
        !(isDesktop$ | async) || (isLayoutVertical$ | async) ? 'over' : 'side'
      "
      [opened]="(isDesktop$ | async) && !(isLayoutVertical$ | async)"
      class="sidenav"
    >
      <ng-container *ngTemplateOutlet="sidenavRef"></ng-container>
    </mat-sidenav>

    <mat-sidenav
      #quickpanel
      [fixedInViewport]="!(isDesktop$ | async)"
      class="quickpanel"
      mode="over"
      position="end"
    >
      <ng-container *ngTemplateOutlet="quickpanelRef"></ng-container>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
      <ng-container *ngTemplateOutlet="toolbarRef"></ng-container>

      <main class="content">
        <div
          [ngClass]="notificationPanel && 'open'"
          class="card rounded-0 notification-cntr border-r border-radius-0 box-shadow-3 bg-light-gray"
        >
          <div
            class="py-6 h-20 border-b flex px-gutter"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <h2 class="title my-0 flex" fxFlex fxLayoutAlign="start center">
              <span class="material-symbols-outlined text-primary mr-2">
                notifications
              </span>
              <span class="mr-8"
                >Notifications
                <span class="item-badge text-white bg-primary">{{
                  itsmNotificationsList.length
                }}</span></span
              >
              <span
                class="material-symbols-outlined close"
                (click)="closeNotificationPanel()"
              >
                close
              </span>
            </h2>
          </div>

          <h3
            *ngIf="!itsmNotificationsList.length"
            class="p-gutter text-hint font-semibold"
          >
            No Notifications
          </h3>

          <div
            class="p-gutter notifications-inner-cntr"
            *ngIf="itsmNotificationsList.length"
          >
            <div class="pb-2" fxLayout="row" fxLayoutAlign=" start center">
              <span>
                <mat-checkbox
                  class="example-margin"
                  color="primary"
                  [checked]="allSelected"
                  [indeterminate]="someSelected()"
                  (change)="selectAll($event.checked)"
                >
                  Select All
                </mat-checkbox>
              </span>
              <span fxFlex></span>
              <button
                *ngIf="allSelected || someSelected()"
                color="primary"
                class="mat-mini"
                (click)="acknowledgeAllTickets()"
                mat-stroked-button
              >
                Aknowledge Selected
              </button>
            </div>
            <div
              class="card box-shadow-0 px-6 py-4 mb-3 notification"
              *ngFor="let notification of itsmNotificationsList"
            >
              <div class="btn-cntr p-gutter bg-primary-light">
                <button color="primary" (click)="acknowledgeTicket(notification)" class="mat-mini" mat-flat-button>
                  Aknowledge
                </button>
              </div>
              <h5 class="font-semibold text-secondary">
                <mat-checkbox
                  class="mr-2"
                  color="primary"
                  [(ngModel)]="notification.selected"
                  (ngModelChange)="updateAllComplete()"
                >
                </mat-checkbox
                >{{ notification.title }}
              </h5>
              <p class="text-sm">
                {{ notification.shortNote }}
              </p>
              <div fxLayout="row" class="mt-2" fxLayoutAlign="end">
                <small class="text-hint">{{ notification.ticketID }}</small>
                <span fxFlex></span>
                <small class="text-hint">{{
                  notification.createdDate | date : "MMM d, yy, h:mm a"
                }}</small>
              </div>
            </div>
          </div>
        </div>
        <router-outlet></router-outlet>
      </main>

      <ng-container *ngTemplateOutlet="footerRef"></ng-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<div
  *ngIf="newNotification"
  [ngClass]="received ? 'received' : ''"
  class="notification-alert bg-primary p-gutter"
>
  <span
    class="material-symbols-outlined close cursor-pointer"
    (click)="closeNotification()"
  >
    close
  </span>
  <h5 class="flex font-semibold mb-3">
    <span class="material-symbols-outlined mr-3"> notifications_active </span>
    New Notification
  </h5>
  <p>{{ notificationList[notificationList.length - 1] }}</p>
</div>
