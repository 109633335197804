import { NOCUsers } from "src/app/_modules/user-management/interfaces/user-model";
import { NocModule } from "./modules.model";
import { NocRole } from "./roles.model";

export interface UserRole {
  id: number;
  name: string;
  parentId: number;
  orderNumber: number;
  isWrite: boolean;
  isRead: boolean;
  isCreate: boolean;
  isDelete: boolean;
  isFull: boolean;
  createdDate: Date;
  modifiedDate: string;
  createdBy: number;
  modifiedBy: number;
}

export class NocUserRoleAndPermissions {
  navmenuItems: NocModule[] = [];
  nocRoles: NocRole[] = [];
  userRole: UserRole[];
  userDetails: NOCUsers;

  constructor(
    userdetails: NOCUsers,
    roles: NocRole[],
    userrole: UserRole[],
    modules: NocModule[]
  ) {
    this.navmenuItems = modules;
    this.nocRoles = roles;
    this.userRole = userrole;
    this.userDetails = userdetails;
  }

  get menuItems() {
    return this.navmenuItems;
  }

  get rolesList() {
    return this.nocRoles;
  }

  //TODO : Imegrate with back-end api
  get renderMenuItems() {
    const items = [];
    return [];
  }

  render(moduleId: number) {
    // this.userDetails.roleId;
    if (this.userDetails && this.userDetails.roleId === 0) {
      return true;
    } else {
      const canRenderIndex = this.userRole.findIndex((module) => {
        return (
          module.id === moduleId &&
          (module.isRead || module.isWrite || module.isCreate)
        );
      });
      if (canRenderIndex !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  isRead(moduleId: number) {
    return this.render(moduleId);
  }

  isWrite(moduleId: number) {
    // this.userDetails.roleId;
    if (this.userDetails && this.userDetails.roleId === 0) {
      return true;
    } else {
      const canRenderIndex = this.userRole.findIndex((module) => {
        return module.id === moduleId && module.isWrite;
      });
      if (canRenderIndex !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  isCreate(moduleId: number) {
    if (this.userDetails && this.userDetails.roleId === 0) {
      return true;
    } else {
      const canRenderIndex = this.userRole.findIndex((module) => {
        return module.id === moduleId && module.isCreate;
      });
      if (canRenderIndex !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }
}
