import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "../auth/auth-services/authentication.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          this.router.navigate(["/un-authorised"]);
          location.reload();
        }
        if(err.status === 400){
          //DO NOTHING
        }
        else if(err.status === 404){
          //DO NOTHING
        }
        else{
          // this.snackbar.open(`Error Code: ${err.status} - Something went wrong!. Please try again`, "OK", {
          //   duration: 3000,
          // });
        }
        let errorMessage = "";
        if (err.error instanceof ErrorEvent) {
          errorMessage = err.error.message;
        } else {
          errorMessage = `Error Code: ${err.status}\nMessage: ${err.error.ErrorMessage}`;
        }
        return throwError(errorMessage);
      })
    );
  }
}
