export class NOCUsers {
  firstName: string;
  middleName: string;
  lastName: string;
  mobile: string;
  sapid: string;
  emailAddress: string;
  emiratesId: string;
  position: string;
  roleId: number;
  id: number;
  createdDate: Date;
  modifiedDate: Date;
  createdBy: number;
  modifiedBy: number;
  themeColorId: number;
  themeStyleId: number;
  active: boolean;
  isLocked: boolean;
  private profilePictureData?: string | ArrayBuffer;

  constructor(user) {
    this.id = user.id;
    this.active = user.active;
    this.isLocked = user.isLocked;
    this.firstName = user.firstName;
    this.middleName = user.middleName;
    this.lastName = user.lastName;
    this.mobile = user.mobile;
    this.sapid = user.sapid;
    this.emailAddress = user.emailAddress;
    this.emiratesId = user.emiratesId;
    this.position = user.position;
    this.roleId = user.roleId;
    this.createdDate = user.createdDate;
    this.modifiedDate = new Date(user.modifiedDate);
    this.createdBy = user.createdBy;
    this.modifiedBy = user.modifiedBy;
    this.themeColorId = user.themeColorId;
    this.themeStyleId = user.themeStyleId;
    // this.profilePictureData = user.profilePicture;
  }

  get name() {
    let name = "";
    return (name = this.firstName + " " + this.lastName);
  }

  get initials() {
    return (this.firstName[0] + "" + this.lastName[0]);
  }

  set name(value) {}

  get profilePicture(): string | ArrayBuffer {
    return this.profilePictureData;
  }
}
