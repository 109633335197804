<div *ngIf="!documentLoaded" id="vex-splash-screen">
  <div class="wrapper">
    <div class="ball-scale-multiple">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>



<ng-template #sidenavRef>
  <vex-sidenav [items]="navItems" [collapsed]="sidenavCollapsed$ | async"></vex-sidenav>
</ng-template>

<ng-template #toolbarRef>
  <vex-toolbar [hasShadow]="toolbarShadowEnabled$ | async"
               [mobileQuery]="!(isDesktop$ | async) || !(isLgDesktop$ | async)"
               class="vex-toolbar"></vex-toolbar>
</ng-template>

<ng-template #footerRef>
  <vex-footer *ngIf="isFooterVisible$ | async" class="vex-footer"></vex-footer>
</ng-template>

<ng-template #quickpanelRef>
  <vex-quickpanel></vex-quickpanel>
</ng-template>

<vex-layout [footerRef]="footerRef"
            [quickpanelRef]="quickpanelRef"
            [sidenavRef]="sidenavRef"
            [toolbarRef]="toolbarRef"></vex-layout>

<vex-config-panel-toggle (openConfig)="configpanel.open()"></vex-config-panel-toggle>

<!-- CONFIGPANEL -->
<vex-sidebar #configpanel [invisibleBackdrop]="true" position="right">
  <vex-config-panel></vex-config-panel>
</vex-sidebar>
<!-- END CONFIGPANEL -->
