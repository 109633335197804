import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  constructor(private _httpClient: HttpClient) { }

  uploadRoomFile(formdata: FormData, roomId: number, fileType: string): Observable<any> {
    return this._httpClient.post<any>(
      environment.apiUrl +
      `/FileUpload/upload-room-file?roomId=${roomId}&fileType=${fileType}`,
      formdata,
    );
  }

  // get Room files
  getRoomFiles(roomId: number): Observable<any> {
    return this._httpClient.get<any[]>(
      environment.apiUrl + `/FileUpload/getall-room-files?roomId=${roomId}`
    );
  }

  deleteFile(fileId: number): Observable<any> {
    return this._httpClient.delete(
      `${environment.apiUrl}/FileUpload/delete-file?fileId=${fileId}`
    );
  }

  //asset
  uploadAssetFile(formdata: FormData, assetId: number, fileType: string): Observable<any> {
    return this._httpClient.post<any>(
      environment.apiUrl +
      `/FileUpload/upload-asset-file?assetId=${assetId}&fileType=${fileType}`,
      formdata,
    );
  }

  //asset
  uploadAssetFiles(formdata: FormData,): Observable<any> {
    return this._httpClient.post<any>(
      environment.apiUrl +
      `/FileUpload/upload-asset-files`,
      formdata,
    );
  }

  // get Asset files
  getAssetFiles(assetId: number): Observable<any> {
    return this._httpClient.get<any[]>(
      environment.apiUrl + `/FileUpload/getall-asset-files?assetId=${assetId}`
    );
  }



  uploadMapsBackground(formdata: FormData, fileType): Observable<any> {
    return this._httpClient.post<any>(
      environment.apiUrl +
      `/FileUpload/upload-mapimage?fileType=${fileType}`,
      formdata,
    );
  }

  getMapsBgImage(): Observable<any> {
    return this._httpClient.get<any[]>(
      environment.apiUrl + `/FileUpload/get-map-file`
    );
  }

  uploadLogoImage(formdata: FormData, fileType): Observable<any> {
    return this._httpClient.post<any>(
      environment.apiUrl +
      `/FileUpload/upload-logoimage?fileType=${fileType}`,
      formdata,
    );
  }

  getLogoFiles(): Observable<any> {
    return this._httpClient.get<any[]>(
      environment.apiUrl + `/FileUpload/get-logo-file`
    );
  }


  uploadLoginBgImage(formdata: FormData, fileType: string): Observable<any> {
    return this._httpClient.post<any>(
      environment.apiUrl +
      `/FileUpload/upload-background?fileType=${fileType}`,
      formdata,
    );
  }

  getLoginBgFile(): Observable<any> {
    return this._httpClient.get<any[]>(
      environment.apiUrl + `/FileUpload/get-background-file`
    );
  }

  updateChartNames(params): Observable<any> {
    return this._httpClient.put<any[]>(
      environment.apiUrl + "/Charts/UpdateChartConfig",
      params
    );
  }
}
