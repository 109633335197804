import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from "@angular/router";
import { Subscription } from "rxjs";
import { first, take, takeLast, takeUntil, takeWhile } from "rxjs/operators";
import { LoggedInRole } from "src/app/_shared/_models/logged-in-role.model";
import { NocUserRoleAndPermissions } from "src/app/_shared/_models/role-and-permissions.model";
import { AppInitService } from "../services/app-init.service";
import { AuthenticationService } from "../auth/auth-services/authentication.service";

@Injectable({ providedIn: "root" })
export class RolesAuthGuard implements CanActivate {
  subscription: Subscription;

  constructor(
    private router: Router,
    private acRoute: ActivatedRoute,
    private _authService: AuthenticationService,
    private _initService: AppInitService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.subscription = this._initService
      .getRoleAndPermissionValue()
      .pipe(take(2))
      .subscribe((role: NocUserRoleAndPermissions) => {
        if (role) {
          if (role.render(route.data.id)) {
            this.subscription && this.subscription.unsubscribe()
            return true;
          } else {
            this.router.navigate(["/un-authorised"]);
            return false;
          }
        }
      });

    return true;
  }

}
