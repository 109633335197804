<mat-dialog-content fxLayout="column">
  <div class="py-6">
    Are you sure you want to {{ data.action }}
    <span *ngIf="data.module" class="font-bold text-primary">{{
      data.module
    }}</span>
    ? <span *ngIf="data.undone">This action cannot be undone</span>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button mat-dialog-close type="button">
    CANCEL
  </button>
  <button
    color="{{ data.color ? data.color : 'warn' }}"
    class="text-uppercase"
    mat-flat-button
    (click)="confirm()"
  >
    {{ data.action }}
  </button>
</mat-dialog-actions>
