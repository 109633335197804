import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AppInitService } from "../services/app-init.service";
import { AuthenticationService } from "../auth/auth-services/authentication.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private _authService: AuthenticationService,
    private _initService: AppInitService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this._authService.currentUserValue;

    if (currentUser) {
      if (currentUser.isTempPassword) {
        // re-direct to rest password
        this.router.navigate(["/reset-password"]);
        return false;
      } else {
        // logged in so return true
        return true;
      }
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    return false;
  }

}

