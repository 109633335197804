import { mergeDeep } from '../utils/merge-deep';
import { ConfigName } from '../interfaces/config-name.model';
import { Config } from '../interfaces/config.model';

const defaultConfig: Config = {
  id: ConfigName.apollo,
  name: 'Classic',
  imgSrc: '',
  layout: 'horizontal',
  boxed: false,
  sidenav: {
    title: 'VEX',
    imageUrl: 'assets/img/logo-dark-theme.png',
    darkImageUrl: 'assets/img/logo-light-theme.png',
    showCollapsePin: true,
    state: 'collapsed'
  },
  toolbar: {
    fixed: false
  },
  navbar: {
    position: 'below-toolbar'
  },
  footer: {
    visible: false,
    fixed: false
  }
};

export const configs: Config[] = [
  defaultConfig,
  mergeDeep({ ...defaultConfig }, {
    id: ConfigName.ares,
    name: 'Material',
    imgSrc: '',
    toolbar: {
      fixed: false
    },
    navbar: {
      position: 'in-toolbar'
    },
    footer: {
      fixed: false
    }
  }),
  mergeDeep({ ...defaultConfig }, {
    id: ConfigName.zeus,
    name: 'Menu Collapsed',
    imgSrc: '',
    sidenav: {
      state: 'collapsed'
    },
  })
];
